import {
  AdjustmentsIcon,
  ChartBarIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentReportIcon,
  DocumentSearchIcon,
  DownloadIcon,
  HomeIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  UploadIcon,
  UsersIcon,
} from '@heroicons/react/outline'

import React from 'react'

export const NavigationItems = [
  {
    title: 'Anasayfa',
    icon: (props) => <HomeIcon {...props} />,
    link: '/',
  },
  {
    title: 'Anakod',
    icon: (props) => <CubeIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/maincode/list',
      },
      {
        title: 'Olustur',
        link: '/maincode/create',
      },
    ],
  },
  {
    title: 'Buluntu',
    icon: (props) => <CubeTransparentIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/artifact/list',
      },
      {
        title: 'Olustur',
        link: '/artifact/create',
      },
      {
        title: 'Envanterlik Eserler',
        link: '/artifact/inventory',
      },
      {
        title: 'Konservasyon Listesi',
        link: '/artifact/conservation',
      },
      {
        title: 'Restorasyon Listesi',
        link: '/artifact/restoration',
      },
      {
        title: 'Buluntu Galerisi',
        link: '/artifact/gallery',
      },
    ],
  },
  {
    title: 'Acma Rapor',
    icon: (props) => <DocumentReportIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/report/list',
      },
      {
        title: 'Olustur',
        link: '/report/create',
      },
    ],
  },
  {
    title: 'Evrak Yonetimi',
    icon: (props) => <DocumentSearchIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/document/list',
      },
      {
        title: 'Olustur',
        link: '/document/create',
      },
    ],
  },
  {
    title: 'Demirbas',
    icon: (props) => <OfficeBuildingIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/fixture/list',
      },
      {
        title: 'Olustur',
        link: '/fixture/create',
      },
    ],
  },
  {
    title: 'Yayinlar',
    icon: (props) => <NewspaperIcon {...props} />,
    subNav: [
      {
        title: 'Listele',
        link: '/article/list',
      },
      {
        title: 'Olustur',
        link: '/article/create',
      },
    ],
  },
  {
    title: 'Istatistikler',
    icon: (props) => <ChartBarIcon {...props} />,
    link: '/statistic',
  },
  {
    title: 'Yonetim',
    icon: (props) => <AdjustmentsIcon {...props} />,
    subNav: [
      {
        title: 'Genel Ayarlar',
        link: '/management-settings',
      },
      {
        title: 'Listeler',
        link: '/management/list',
      },
      {
        title: 'Kullanicilar',
        link: '/management/users',
      },
      {
        title: 'Islem Gecmisi',
        link: '/management/operation-logs',
      },
      {
        title: 'Giris Loglari',
        link: '/management/login-logs',
      },
      {
        title: 'Hatali Girisler',
        link: '/management/incorrect-login',
      },
    ],
  },
]

export const StatsData = [
  {
    title: 'Anakod',
    stats: 1536,
    id: 'MainCode',
    link: '/maincode/list',
    icon: NavigationItems[1].icon,
    theme: { bgColor: 'bg-indigo-100', textColor: 'text-indigo-400' },
  },
  {
    title: 'Buluntu',
    id: 'Artifact',
    stats: 18483,
    link: '/artifact/list',
    icon: NavigationItems[2].icon,
    theme: { bgColor: 'bg-blue-100', textColor: 'text-blue-400' },
  },
  {
    title: 'Acma Rapor',
    id: 'ExcavationReport',
    stats: 2801,
    link: '/open-report/list',
    icon: NavigationItems[3].icon,
    theme: { bgColor: 'bg-purple-100', textColor: 'text-purple-400' },
  },
  {
    title: 'Evrak',
    id: 'Document',
    stats: 1137,
    link: '/Document/list',
    icon: NavigationItems[4].icon,
    theme: { bgColor: 'bg-pink-100', textColor: 'text-pink-400' },
  },
  {
    title: 'Demirbas',
    id: 'Asset',
    stats: 77,
    link: '/fixture/list',
    icon: NavigationItems[5].icon,
    theme: { bgColor: 'bg-red-100', textColor: 'text-red-400' },
  },
  {
    title: 'Kullanici',
    id: 'ExcavationUser',
    stats: 20,
    link: '/management/list',
    icon: (props) => <UsersIcon {...props} />,
    theme: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-400' },
  },
]

export default NavigationItems

export const generateSingleItemUrl = (feature, id, excavationId, params) => {
  if (params) {
    return `${process.env.HOST}/api/${feature}/${id}/?current-excavation=${excavationId}&${params}`
  }
  return `${process.env.HOST}/api/${feature}/${id}/?current-excavation=${excavationId}`
}
export const generateListUrl = (feature, excavationId, params) => {
  if (params) {
    return `${process.env.HOST}/api/${feature}/?current-excavation=${excavationId}&${params}`
  }
  return `${process.env.HOST}/api/${feature}/?current-excavation=${excavationId}`
}

const iconProps = {
  className: 'flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400',
  'aria-hidden': 'true',
}
export const documentType = {
  1: {
    label: 'Gelen',
    get icon() {
      return <DownloadIcon {...iconProps} />
    },
  },
  2: {
    label: 'Giden',
    get icon() {
      return <UploadIcon {...iconProps} />
    },
  },
  3: {
    label: 'Tutanak',
    get icon() {
      return <DocumentReportIcon {...iconProps} />
    },
  },
}

export const reportTypes = [
  { id: 1, title: 'Günlük' },
  { id: 2, title: 'Haftalık' },
  { id: 3, title: 'Günlük' },
  { id: 4, title: 'Kapanış' },
]
