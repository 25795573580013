import { generateListUrl } from '@utils/constants' // set Cookies
import Cookies from 'js-cookie'
import Router from 'next/router'

// set Cookies
export const setCookie = (key, value) => {
  if (process.browser) {
    Cookies.set(key, value, {
      expires: 1,
    })
  }
}

// get Cookies
export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key)
  }
}
// remove Cookies
export const removeCookies = (key) => {
  if (process.browser) {
    Cookies.remove(key, {
      expires: 1,
    })
  }
}
// localstorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}
export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key)
  }
}
// authenticate user by pass data to Cookies and localstorage
export const authenticate = (data, next) => {
  // TODO: change the cookie name as specific name like celsusWebToken
  setCookie('celsuswebtoken', data.token)
  setLocalStorage('user', data.email)
  next()
}

export const isAuth = () => {
  if (process.browser) {
    const CookieValid = getCookie('celsuswebtoken')
    if (CookieValid && localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'))
    }
    return false
  }
}

export const login = async (userCredentials) => {
  const res = await fetch(generateListUrl('token', process.env.EXCAVATION_ID), {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(userCredentials),
  })
  if (!res.ok) {
    return { status: res.status, data: 'Kullanici adi veya parola hatali' }
  }
  const response = await res.json()
  return { status: res.status, data: response.token }
}
export const resetCookiesAndLocalStorages = () => {
  removeCookies('celsuswebtoken')
  removeCookies('csrftoken')
  removeCookies('sessionid')
  removeLocalStorage('user')
  removeLocalStorage('recent-maincode')
}
export const logout = (e) => {
  e.preventDefault()
  resetCookiesAndLocalStorages()
  Router.push(`/login`)
}
