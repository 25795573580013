import '../styles/globals.css'
import 'regenerator-runtime/runtime'
import { AppContextProvider } from '@context/app-context'
import { config } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import '@fortawesome/fontawesome-svg-core/styles.css'
import '../styles/ck-override.css'

config.autoAddCss = false

function MyApp({ Component, pageProps }) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  )
  if (pageProps?.pageName === 'login') {
    return <Component {...pageProps} />
  }

  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </AppContextProvider>
  )
}

export default MyApp
