import { getCookie } from '@lib/auth'
import { createContext } from 'react'

export const AppContext = createContext()
function AppContextProvider(props) {
  const token = getCookie('celsuswebtoken')
  const excavation_id = process.env.EXCAVATION_ID

  return (
    <AppContext.Provider
      value={{
        token,
        excavation_id,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export { AppContextProvider }
